/**
 * THIS FILE IS AUTOGENERATED PARTIALLY
 * DO NOT EDIT THE APP VERSION OR RELEASE DATE
 * IF YOU CHANGE ANY KEY NAME BE SURE TO REFLECT THIS CHANGE IN OUR AUTOMATION SCRIPT
 */
import { Version, version as libVersion } from '@igo2/core';

export const version: Version = {
  ...libVersion,
  app: '16.12.0-rc.2',
  releaseDateApp: 1737490259942
};
